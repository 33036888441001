import Cookies from 'universal-cookie';
import config from '../config';
import { api } from '../helpers/util';

const cookies = new Cookies();

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
    error: null,
    message: '',
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData(state) {
      return { ...state, loading: true, error: false }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    selectReferralCode(state, payload) {
      return { ...state, selected: payload }
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
    gotError(state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: dispatch => ({
    async createReferralCodeAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.referralCodes.create,
          data: payload,
        });

        dispatch.referralCodes.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully created referral code",
        });
        dispatch.referralCodes.hideNotification({});
        
        dispatch.referralCodes.getAllReferralCodesAction({});
      } catch (error) {
        dispatch.referralCodes.gotError(error.response);

        dispatch.referralCodes.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong creating the referral code.",
        });

        dispatch.referralCodes.hideNotification({});

        console.log(error);
      }
    },

    async updateSingleReferralCodeAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.referralCodes.update,
          data: payload,
        });

        dispatch.referralCodes.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully updated referral code",
        });
        dispatch.referralCodes.hideNotification({});

        dispatch.referralCodes.getOneReferralCodeAction({
          _id: payload.query._id
        });

      } catch (error) {
        dispatch.referralCodes.gotError(error.response);

        dispatch.referralCodes.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong updating the referral code.",
        });
        dispatch.referralCodes.hideNotification({});

        console.log(error);
      }
    },

    async getOneReferralCodeAction(payload, rootState) {
      try {
        const { data: referralCode } = await api({
          method: 'post',
          state: rootState,
          url: config.api.referralCodes.getOne,
          data: payload,
        });

        dispatch.referralCodes.gotData(referralCode);

        const query = referralCode.type === "merchant" ? {
          "referralCodes.merchant": referralCode.referralCode
        } : {
          "referralCodes.shopper": referralCode.referralCode
        };

        dispatch.processedTransactions.getAllProcessedTransactionsAction(query);

        // Get merchants for referral code
        await dispatch.merchants.getAllMerchantsAction({
          referralCode: referralCode.referralCode
        });

        // Get shoppers for referral code
        await dispatch.shoppers.getAllShoppersAction({
          referralCode: referralCode.referralCode
        });

      } catch (error) {
        dispatch.referralCodes.gotError(error);

        dispatch.referralCodes.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went getting the referral code data.",
        });
        dispatch.referralCodes.hideNotification({});

        console.log(error);
      }
    },

    async getAllReferralCodesAction(payload = {}, rootState) {
      try {
        const env = cookies.get("BoonENV");

        payload.env = env;
        
        const { data: allReferralCodes } = await api({
          method: 'post',
          state: rootState,
          url: config.api.referralCodes.getMany,
          data: payload,
        });

        dispatch.referralCodes.gotData(allReferralCodes);
      } catch (error) {
        dispatch.referralCodes.gotError(error.response);

        dispatch.referralCodes.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong fetching the referral codes.",
        });
        dispatch.referralCodes.hideNotification({});

        console.log(error);
      }
    },

    async getAllReferralCodesForSalesRepAction(payload = {}, rootState) {
      try {
        const { data: allReferralCodes } = await api({
          method: 'post',
          state: rootState,
          url: config.api.referralCodes.getMany,
          data: payload,
        });

        dispatch.referralCodes.gotData(allReferralCodes);

        dispatch.processedTransactions.getAllProcessedTransactionsAction({
          "$or": [{
            "salesRepIds.merchant": payload.salesRepId
          }, {
            "salesRepIds.shopper": payload.salesRepId
          }]
        });

      } catch (error) {
        dispatch.referralCodes.gotError(error.response);

        dispatch.referralCodes.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong fetching the referral codes.",
        });
        dispatch.referralCodes.hideNotification({});

        console.log(error);
      }
    },
  }),
}
