import Cookies from 'universal-cookie';
import config from '../config';
import { api } from '../helpers/util';

const cookies = new Cookies();

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
    error: null,
    message: '',
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData(state) {
      return { ...state, loading: true, error: false }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    selectSalesRep(state, payload) {
      return { ...state, selected: payload }
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
    gotError(state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: dispatch => ({
    async createSalesRepAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.salesReps.create,
          data: payload,
        });

        dispatch.salesReps.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully created sales representative",
        });
        dispatch.salesReps.hideNotification({});
        
        dispatch.salesReps.getAllSalesRepsAction({});
      } catch (error) {
        dispatch.salesReps.gotError(error.response);

        dispatch.salesReps.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong creating the sales rep.",
        });

        dispatch.salesReps.hideNotification({});

        console.log(error);
      }
    },

    async updateSingleSalesRepAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.salesReps.update,
          data: payload,
        });

        dispatch.salesReps.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully updated sales representative",
        });
        dispatch.salesReps.hideNotification({});

        dispatch.salesReps.getOneSalesRepAction({
          _id: payload.query._id
        })

      } catch (error) {
        dispatch.salesReps.gotError(error.response);

        dispatch.salesReps.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong updating the sales rep.",
        });
        dispatch.salesReps.hideNotification({});

        console.log(error);
      }
    },

    async getOneSalesRepAction(payload, rootState) {
      try {
        const { data: salesRep } = await api({
          method: 'post',
          state: rootState,
          url: config.api.salesReps.getOne,
          data: payload,
        });

        dispatch.salesReps.gotData(salesRep);
        
        // Get referral codes
        const { data: referralCodesData } = await api({
          method: 'post',
          state: rootState,
          url: config.api.referralCodes.getMany,
          data: {
            salesRepId: payload._id
          },
        });

        const referralCodes = referralCodesData.map(referralCode => referralCode.referralCode);

         // Get merchants for referral code
         await dispatch.merchants.getAllMerchantsAction({
          "$in": ["referralCode", referralCodes]
        });

        // Get shoppers for referral code
        await dispatch.shoppers.getAllShoppersAction({
          "$in": ["referralCode", referralCodes]
        });

      } catch (error) {
        dispatch.salesReps.gotError(error);

        dispatch.salesReps.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went getting the sales rep data.",
        });
        dispatch.salesReps.hideNotification({});

        console.log(error);
      }
    },

    async getAllSalesRepsAction(payload, rootState) {
      try {
        const env = cookies.get("BoonENV");

        const { data: salesReps } = await api({
          method: 'post',
          state: rootState,
          url: config.api.salesReps.getMany,
          data: {
            env
          },
        });

        dispatch.salesReps.gotData(salesReps);
      } catch (error) {
        dispatch.salesReps.gotError(error.response);

        dispatch.salesReps.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong fetching the sales reps.",
        });
        dispatch.salesReps.hideNotification({});

        console.log(error);
      }
    },
  }),
}
