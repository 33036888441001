import React from 'react'
import ReactDOM from 'react-dom'
import Cookies from 'universal-cookie';
// import { combineReducers } from 'redux'


// import createHistory from "history/createBrowserHistory";
// import {
//   // ConnectedRouter,
//   routerReducer,
//   routerMiddleware
// } from "react-router-redux";

import { connectRouter, routerMiddleware } from 'connected-react-router'

import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'

import { createBrowserHistory } from 'history'

import Router from 'router'
import { init } from '@rematch/core'
import Localization from './components/LayoutComponents/Localization'
import * as serviceWorker from './serviceWorker'
import * as models from './models'

// app styles
import './global.scss'

// Set environment
const cookies = new Cookies();
if (!cookies.get("BoonENV")) {
  cookies.set('BoonENV', 'production', { path: '/' });
}

const history = createBrowserHistory()

if (process.env.NODE_ENV === 'development' && true) {
  // middlewares.push(logger)
}


const routeMiddleware = routerMiddleware(history)
const middlewares = [routeMiddleware]
const store = init({
  redux: {
    middlewares,
    // combineReducers: connectRouter(history)(state => state)
    reducers: {
      router: connectRouter(history)
    }
  }, models,
})


ReactDOM.render(
  <Provider store={store}>
    <Localization locale="en-US">
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.register()
export { store, history }
