import Cookies from 'universal-cookie';
import config from '../config'
import { api } from '../helpers/util'

const cookies = new Cookies();

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
    error: null,
    message: '',
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData(state) {
      return { ...state, loading: true, error: false }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    selectMerchant(state, payload) {
      return { ...state, selected: payload }
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
    gotError(state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: dispatch => ({
    async createMerchantAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.merchants.create,
          data: payload,
        });

        dispatch.merchants.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully created merchant",
        });
        dispatch.merchants.hideNotification({});
        
        dispatch.merchants.getAllMerchantsAction({});
      } catch (error) {
        dispatch.merchants.gotError(error.response);

        dispatch.merchants.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong creating the merchant.",
        });

        dispatch.merchants.hideNotification({});

        console.log(error);
      }
    },

    async updateSingleMerchantAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.merchants.update,
          data: payload,
        });

        dispatch.merchants.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully updated merchant",
        });
        dispatch.merchants.hideNotification({});

      } catch (error) {
        dispatch.merchants.gotError(error.response);

        dispatch.merchants.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong updating the merchant.",
        });
        dispatch.merchants.hideNotification({});

        console.log(error);
      }
    },

    async getOneMerchantAction(payload, rootState) {
      try {
        const { data: merchant } = await api({
          method: 'post',
          state: rootState,
          url: config.api.merchants.getOne,
          data: payload,
        });

        dispatch.merchants.gotData(merchant);
      } catch (error) {
        dispatch.merchants.gotError(error);

        dispatch.merchants.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went getting the merchant data.",
        });
        dispatch.merchants.hideNotification({});

        console.log(error);
      }
    },

    async getAllMerchantsAction(payload, rootState) {
      try {

        const env = cookies.get("BoonENV");

        const { data: allMerchants } = await api({
          method: 'post',
          state: rootState,
          url: config.api.merchants.getMany,
          data: {
            env
          },
        });

        dispatch.merchants.gotData(allMerchants);
      } catch (error) {
        dispatch.merchants.gotError(error.response);

        dispatch.merchants.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong fetching the merchants.",
        });
        dispatch.merchants.hideNotification({});

        console.log(error);
      }
    },
  }),
}
