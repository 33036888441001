import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Type to search...',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.hotLeads': 'Hot Leads',
  'topBar.profileMenu.lists': 'Lists',
  'topBar.profileMenu.templates': 'Templates',
  'topBar.profileMenu.queues': 'Queues',
  'topBar.profileMenu.automation': 'Automation',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}
