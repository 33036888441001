import { push } from "connected-react-router"
import jwtDecode from "jwt-decode";
import { api } from '../helpers/util'
import config from "../config";


let token;
let decodedUser = {};

try{
  token = localStorage.getItem('token');
  decodedUser = jwtDecode(token);
} catch(err) {
  token = null;
}

export default {
  state: {
    _id: '',
    name: "",
    firstName: "",
    lastName: "",
    email: '',
    imageURL: '',
    authorized: !!token,
    hasCreatedProfile: false,
    token,
    loading: false,
    error: false,
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    },
    ...decodedUser
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    login(state, payload) {
      return {...state, ...payload, error: false, authorized: true}
    },
    gotData(state, payload) {
      return { ...state, ...payload, loading: false, error: false, authorized: true, message: '' }
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
    errorLogin(state, payload) {
      return {...state, ...payload, error: true}
    }
  },
  effects: (dispatch) => ({
    async loginAction(payload, rootState) {
      const {email, password} = payload;

      try {
        const userData = {email, password};

        const {data} = await api({
          method: 'post',
          state: rootState,
          url: config.api.user.login,
          data: userData,
        });

        data.user.token = data.token;
        dispatch.user.gotData(data.user);
        localStorage.setItem('token', data.token);
        dispatch.user.login(data.user);

        dispatch(push("/sales-reps"));

      } catch (error) {
        dispatch.user.errorLogin(error);
        dispatch.user.showNotification({
          type: "error",
          message: "Unable to log in!",
          description: "Make sure your username and password are correct!",
        });
        dispatch.user.hideNotification({});
        console.log(error)
      }
    },

    async updateUser (payload, rootState) {
      try{

        const {data} = await api({
          method: 'put',
          state: rootState,
          url: config.api.user.updateUser,
          data: payload,
        });

        data.user.token = data.token;
        dispatch.user.gotData(data.user);
        localStorage.setItem('token', data.token);
        dispatch.user.login(data.user);

        dispatch(push("/sales-reps"));

      } catch (error) {
        dispatch.user.errorLogin(error);
      }
    },

    // async createUser (payload, rootState) {
    //   try{

    //     const { data } = await api({
    //       method: 'post',
    //       state: rootState,
    //       url: config.api.user.createUser,
    //       data: payload,
    //     });

    //     data.user.token = data.token;
    //     dispatch.user.gotData(data.user);
    //     localStorage.setItem('token', data.token);
    //     dispatch.user.login(data.user);

    //   } catch (error) {
    //     dispatch.user.errorLogin(error);
    //   }
    // },

    logoutAction() {
      localStorage.removeItem('token');
      window.location.reload();
    }
  })
}
