import Cookies from 'universal-cookie';
import config from '../config'
import { api } from '../helpers/util'

const cookies = new Cookies();

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
    error: null,
    message: '',
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    }
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData(state) {
      return { ...state, loading: true, error: false }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    selectProcessedTransaction(state, payload) {
      return { ...state, selected: payload }
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
    gotError(state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: dispatch => ({
    async updateSingleProcessedTransactionAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.processedTransactions.update,
          data: payload,
        });

        dispatch.processedTransactions.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully updated transaction",
        });
        dispatch.processedTransactions.hideNotification({});

      } catch (error) {
        dispatch.processedTransactions.gotError(error.response);

        dispatch.processedTransactions.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong updating the transaction.",
        });
        dispatch.processedTransactions.hideNotification({});

        console.log(error);
      }
    },

    async getOneProcessedTransactionAction(payload, rootState) {
      try {
        const { data: transaction } = await api({
          method: 'post',
          state: rootState,
          url: config.api.processedTransactions.getOne,
          data: payload,
        });

        dispatch.processedTransactions.gotData(transaction);
      } catch (error) {
        dispatch.processedTransactions.gotError(error);

        dispatch.processedTransactions.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went getting the transaction data.",
        });
        dispatch.processedTransactions.hideNotification({});

        console.log(error);
      }
    },

    async getAllProcessedTransactionsAction(payload, rootState) {
      try {
        const env = cookies.get("BoonENV");

        payload.env = env;

        const { data: allProcessedTransactions } = await api({
          method: 'post',
          state: rootState,
          url: config.api.processedTransactions.getMany,
          data: payload,
        });

        dispatch.processedTransactions.gotData(allProcessedTransactions);
      } catch (error) {
        dispatch.processedTransactions.gotError(error.response);

        dispatch.processedTransactions.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong fetching the processed transactions.",
        });
        dispatch.processedTransactions.hideNotification({});

        console.log(error);
      }
    }
  }),
}
