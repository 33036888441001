import React from 'react'
import { Link } from 'react-router-dom'
import { Switch } from 'antd'
import Cookies from 'universal-cookie';
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

const cookies = new Cookies();

class TopBar extends React.Component {

  state = {
    environment: cookies.get("BoonENV")
  }

  toggleActivation(checked) {
    const env = checked ? "production" : "staging";

    cookies.set('BoonENV', env, { path: '/' });

    this.setState({
      environment: env
    });

    window.location.reload();
  }
  
  render() {

    const { environment } = this.state;

    return (
      <div className={styles.topbar}>
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <a href="/">
              <img src="/resources/images/logo.png" className={styles.logoCenter} alt="logo" />
            </a>
          </div>
        </div>

        <Switch 
          checked={environment === "production"} 
          onChange={checked => this.toggleActivation(checked)} 
          checkedChildren="Production Data" 
          unCheckedChildren="Staging Data" 
        />

        <div className="mr-auto" />

        <div className="mr-4">
          <Link to="/sales-reps">
            <strong><span>SALES REPS</span></strong>
          </Link>
        </div>

        <div className="mr-4">
          <Link to="/merchants">
            <strong><span>MERCHANTS</span></strong>
          </Link>
        </div>

        <div className="mr-4">
          <Link to="/shoppers">
            <strong><span>SHOPPERS</span></strong>
          </Link>
        </div>

        <div className="mr-4">
          <Link to="/referral-codes">
            <strong><span>REFERRAL CODES</span></strong>
          </Link>
        </div>

        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
