import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  // {
  //   path: '/user/register',
  //   component: loadable(() => import('pages/user/register')),
  //   exact: true,
  // },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/sales-reps',
    component: loadable(() => import('pages/sales-reps')),
    exact: true,
  },
  {
    path: '/sales-rep/:salesRepId',
    component: loadable(() => import('pages/sales-rep')),
  },
  {
    path: '/merchants',
    component: loadable(() => import('pages/merchants')),
    exact: true,
  },
  {
    path: '/shoppers',
    component: loadable(() => import('pages/shoppers')),
    exact: true,
  },
  {
    path: '/referral-codes',
    component: loadable(() => import('pages/referral-codes')),
    exact: true,
  },
  {
    path: '/referral-code/:referralCodeId',
    component: loadable(() => import('pages/referral-code')),
  },
  {
    path: '/edit-profile',
    component: loadable(() => import('pages/edit-profile')),
    exact: true,
  }
]

@connect(({ user }) => ({ user }))
class Router extends React.Component {
  render() {
    const { history } = this.props

    // Base URL
    const baseUrl = "/sales-reps";
   
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to={baseUrl} />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
