import Cookies from 'universal-cookie';
import config from '../config'
import { api } from '../helpers/util'

const cookies = new Cookies();

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
    error: null,
    message: '',
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData(state) {
      return { ...state, loading: true, error: false }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    selectShopper(state, payload) {
      return { ...state, selected: payload }
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
    gotError(state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: dispatch => ({
    async updateSingleShopperAction(payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.shoppers.update,
          data: payload,
        });

        dispatch.shoppers.showNotification({
          type: "success",
          message: "Success!",
          description: "Successfully updated shopper",
        });
        dispatch.shoppers.hideNotification({});

      } catch (error) {
        dispatch.shoppers.gotError(error.response);

        dispatch.shoppers.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong updating the shopper.",
        });
        dispatch.shoppers.hideNotification({});

        console.log(error);
      }
    },

    async getOneShopperAction(payload, rootState) {
      try {
        const { data: shopper } = await api({
          method: 'post',
          state: rootState,
          url: config.api.shoppers.getOne,
          data: payload,
        });

        dispatch.shoppers.gotData(shopper);
      } catch (error) {
        dispatch.shoppers.gotError(error);

        dispatch.shoppers.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went getting the shopper data.",
        });
        dispatch.shoppers.hideNotification({});

        console.log(error);
      }
    },

    async getAllShoppersAction(payload, rootState) {
      try {

        const env = cookies.get("BoonENV");

        const { data: allShoppers } = await api({
          method: 'post',
          state: rootState,
          url: config.api.shoppers.getMany,
          data: {
            env
          },
        });

        dispatch.shoppers.gotData(allShoppers);
      } catch (error) {
        dispatch.shoppers.gotError(error.response);

        dispatch.shoppers.showNotification({
          type: "error",
          message: "Error!",
          description: "Something went wrong fetching the shoppers.",
        });
        dispatch.shoppers.hideNotification({});

        console.log(error);
      }
    },
  }),
}
