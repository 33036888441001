const apiUrl = 'https://boon-api.buildable.io'
// const apiUrl = "http://localhost:4000";

const version = 'v1'

const resources = {
  salesReps: 'sales-reps',
  merchants: 'merchants',
  shoppers: 'shoppers',
  rawTransactions: 'raw-transactions',
  processedTransactions: 'processed-transactions',
  referralCodes: 'referral-codes'
}

export default {
  buildable: {
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDJjYWIxMDA4YTM5NDJkMmE5NjkzNmUiLCJuYW1lIjoiQm9vbiIsIndlYnNpdGUiOiJnZXRib29uLmNvbSIsInBhY2thZ2UiOnsibmFtZSI6IlBybyIsImNvbm5lY3Rpb25zIjoxNTAwMDAsIm1vZHVsZXMiOjUsImVuZHBvaW50cyI6MzB9LCJidWlsZGFibGVJZCI6IjVkMmNhYjEwMDhhMzk0MmQyYTk2OTM2ZSIsImlhdCI6MTU2MzIwODUxOCwiZXhwIjoxNjI2MzIzNzE4LCJhdWQiOiJidWlsZGFibGUtdXNlcnMiLCJpc3MiOiJidWlsZGFibGUifQ.XU93JZj_O0sESIpnGAvipvv2D_s9CW0QoqodJNsuLaQ"
  },

  api: {
    user: {
      login: `${apiUrl}/${version}/login`,
      updateUser: `${apiUrl}/${version}/user`,
      createUser: `${apiUrl}/${version}/users/add`,
      forgotPassword: `${apiUrl}/${version}/forgot/password`,
      resetPassword: `${apiUrl}/${version}/reset/password`
    },
    salesReps: {
      create: `${apiUrl}/${version}/${resources.salesReps}/create`,
      update: `${apiUrl}/${version}/${resources.salesReps}/update`,
      setActivation: `${apiUrl}/${version}/${resources.salesReps}/set-activation`,
      delete: `${apiUrl}/${version}/${resources.salesReps}/delete`,
      getOne: `${apiUrl}/${version}/${resources.salesReps}/get-one`,
      getMany: `${apiUrl}/${version}/${resources.salesReps}/get-many`,
    },
    merchants: {
      create: `${apiUrl}/${version}/${resources.merchants}/create`,
      update: `${apiUrl}/${version}/${resources.merchants}/update`,
      setActivation: `${apiUrl}/${version}/${resources.merchants}/set-activation`,
      delete: `${apiUrl}/${version}/${resources.merchants}/delete`,
      getOne: `${apiUrl}/${version}/${resources.merchants}/get-one`,
      getMany: `${apiUrl}/${version}/${resources.merchants}/get-many`,
    },
    shoppers: {
      create: `${apiUrl}/${version}/${resources.shoppers}/create`,
      update: `${apiUrl}/${version}/${resources.shoppers}/update`,
      setActivation: `${apiUrl}/${version}/${resources.shoppers}/set-activation`,
      delete: `${apiUrl}/${version}/${resources.shoppers}/delete`,
      getOne: `${apiUrl}/${version}/${resources.shoppers}/get-one`,
      getMany: `${apiUrl}/${version}/${resources.shoppers}/get-many`,
    },
    rawTransactions: {
      create: `${apiUrl}/${version}/${resources.rawTransactions}/create`,
      update: `${apiUrl}/${version}/${resources.rawTransactions}/update`,
      setActivation: `${apiUrl}/${version}/${resources.rawTransactions}/set-activation`,
      delete: `${apiUrl}/${version}/${resources.rawTransactions}/delete`,
      getOne: `${apiUrl}/${version}/${resources.rawTransactions}/get-one`,
      getMany: `${apiUrl}/${version}/${resources.rawTransactions}/get-many`,
    },
    processedTransactions: {
      create: `${apiUrl}/${version}/${resources.processedTransactions}/create`,
      update: `${apiUrl}/${version}/${resources.processedTransactions}/update`,
      setActivation: `${apiUrl}/${version}/${resources.processedTransactions}/set-activation`,
      delete: `${apiUrl}/${version}/${resources.processedTransactions}/delete`,
      getOne: `${apiUrl}/${version}/${resources.processedTransactions}/get-one`,
      getMany: `${apiUrl}/${version}/${resources.processedTransactions}/get-many`,
      getManyAggregated: `${apiUrl}/${version}/${resources.processedTransactions}/get-many-aggregated`
    },
    referralCodes: {
      create: `${apiUrl}/${version}/${resources.referralCodes}/create`,
      update: `${apiUrl}/${version}/${resources.referralCodes}/update`,
      setActivation: `${apiUrl}/${version}/${resources.referralCodes}/set-activation`,
      delete: `${apiUrl}/${version}/${resources.referralCodes}/delete`,
      getOne: `${apiUrl}/${version}/${resources.referralCodes}/get-one`,
      getMany: `${apiUrl}/${version}/${resources.referralCodes}/get-many`,
    }
  }
}
